@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Italic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Italic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Thin.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Thin.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 250;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ThinItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 250;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ThinItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraLight.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraLight.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 275;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraLightItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 275;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraLightItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Light.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Light.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-LightItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-LightItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-MediumItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-MediumItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-SemiBold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-SemiBold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-SemiBoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-SemiBoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-BoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-BoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraBold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraBold.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraBoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-ExtraBoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Black.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-Black.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-BlackItalic.woff') format('woff');
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins'), url('https://fonts.cdnfonts.com/s/16009/Poppins-BlackItalic.woff') format('woff');
}


