@-dark-blue: #001529;

.screen {
  .site-layout {
    .initial-screen {

      // ? CARDS SECTION
      .cards {
        .card {
          border-radius: 12.5px;
          cursor:default;
          height:100%;
          text-align: center;

          .title {
            font-weight: 600;
            font-size: 1.5rem;

            @media screen and (max-width: 960px) {
              font-size: 0.75rem;
            }
          }

          .quantity {
            margin-top: 5%;
            font-size: 2.75rem;
            margin-bottom: -4%;

            @media screen and (max-width: 960px) {
              font-size: 0.75rem;
            }
          }
        }
      }

      // ? CHARTS SECTION
      .charts {
        margin-top: 2.5%;
        margin-bottom: 2.5%;

        h2 {
          font-weight: 600;
        }

        .label {
          color: @-dark-blue;
        }

        .value {
          color: @-dark-blue;
          font-size: 1.5rem;
        }
      }

      .line-chart {
        border-radius: 12.5px;
        background-color: #EFEFEF;
        padding: 3%;
        height: 100%;

        .chart-container {
          display: flex;
          margin-top: 5%;
          height: 80%;

          .sub-container {
            display: flex;
            flex-direction: column;;
            height: 100%;
            width: 100%;
          }

          .yLabel {
            width: 15%;
            font-size: 0.75rem;
            text-align: center;
            writing-mode: vertical-rl; 
            transform: rotate(180deg);
          }

          .xLabel {
            text-align: center;
            font-size: 0.75rem;
          }
        }
      }

      .ring-chart {
        border-radius: 12.5px;
        background-color: #EFEFEF;
        padding: 3%;
        height: 100%;

        .g2-html-annotation {
          font-size: 2rem !important;
        }

        .left-content {
          display: flex;
          flex-direction: column;
        }
      }

      .card {
        border-radius: 12.5px;
        background-color: @-dark-blue;

        .title {
          font-weight: 600;
          font-size: 1rem;
          color: #FFF;
        }

        .ant-table-thead {
          .ant-table-cell {
            background-color: #BAEE55;
            color: @-dark-blue;
            font-weight: 600;
          }
        }

        .ant-table-tbody {
          .ant-table-cell {
            background-color: @-dark-blue;
            color: #FFF;
            font-weight: 600;
            border: none;
          }
        }
      }

      // ? LAST SECTION
      .table-info{

        .timeline {
          border-radius: 12.5px;
          background-color: #212121;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 600;
            font-size: 1rem;
            color: #FFF;
          }

          .ant-timeline {
            margin-top: 2%;
            color: #FFF;
          }
        }

        .ant-table-thead {
          .ant-table-cell {
            background-color: #336BF6;
            color: #FFF;
            font-weight: 600;
          }
        }
      }
    }
  }
}