@-dark-blue: #001529;

.login-screen {
  width: 100%;
  height: 100vh;

  .bg-section {
    height: 100vh;
    .wrapper {
      height: 100%;
      // padding: 1em;
  
      .bg-image {
        background-position-x: center;
        background-image: url("../../../Assets/img/login-bg.jpg");
        background-size: cover;
        // box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
        height: 100%;
      }
    }
  }

  .login-section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: @-dark-blue;

    .login-form-button {
      width: 100%;
      background-color: #BAEE55;
      border-color: #BAEE55;
      color: @-dark-blue;
      border-radius: 16px;
    }

    .spacer {
      padding-left: 5em;
      width: 90%;
    }
  }
}
