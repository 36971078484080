@import './Screens/dashboard.less';
@-dark-blue: #152558;

.screen {
  .wrapper {
    height: 100vh;
    .ant-layout-content {
      max-height: 90%;
      overflow: auto;
    }
  }
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 70px;
    margin: 16px;
  }

  .sider {
    .ant-menu {
      font-weight: 400;
      .ant-menu-item-selected {
        background-color: #445445;
        color: @-dark-blue;
        font-weight: 600;
      }
    }
  }

  .site-layout {
    .screen-title {
      display: flex;
      min-width: 8%;
      justify-content: space-between;

      .ant-tag {
        background-color: #109bf8;
        color: #fff;
        border-color: #109bf8;
        width: 150%;
        text-align: center;
        display: inline-block;
        border-radius: 16px;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        background-color: @-dark-blue;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .site-layout-background {
    background: #fff;
  }
}
